<template lang="pug">
  div.home
    section.hero
      div.container
        div.hero__wrapper
          h1(v-html="$t('home.boostTitle')")
          p.hero__text {{ $t('home.increaseText') }}
          ul.list-style.pl-0
            li
              h4 {{ $t('home.enhanceList') }}
            li
              h4 {{ $t('home.levelList') }}
            li
              h4 {{ $t('home.administrateList') }}
          div.hero__btns
            a.hero__submit.btn-fill(
              href="https://cabinet.easypon.in/registration"
              target="_blank"
            ) {{ $t('btn.register') }}
            p {{ $t('or') }}
            a.hero__submit.btn-blank(
              :href="`https://easypon.in/${ $i18n.locale }/demo`"
              target="_blank"
            ) {{ $t('btn.tryDemo') }}
          button.hero__scroll(v-scroll-to="'#features'")
            img(
              :src="Scroll"
              width=60
              height=60
              alt="scroll"
            )
    section.features
      div.container#features
        h2 {{ $t('home.featuresTitle') }}
        img(
          :src="Features"
          alt="feature"
        ).img__desktop
        img(
          :src="FeaturesMobile"
          alt="feature"
        ).img__mobile
    section.why
      div.container
        h2 {{ $t('home.whyTitle') }}
        ul.list-standard
          li(
            v-for="feature in $t('home.features')"
            :key="feature.title"
          )
            h4 {{ feature.title }}
            p {{ feature.description }}
    section.services
      div.container
        h2 {{ $t('tools.featuresTitle') }}
        ul.services__list
          li(v-for="service in servList")
            .services__list-hero
              img(:src="service.img" alt="icon")
              h3 {{ service.title }}
              p {{ service.text}}
            .services__list-hover
              .hover__content
                h3 {{ service.titleHover }}
                p(v-for="paragraphItem in service.textHover") {{ paragraphItem }}
              .hover__img
                img(
                  :src="service.imgHover"
                  alt="icon"
                )
    //section.balance
    //  div.container
    //    div.balance__wrapper.d-flex.align-center.justify-center
    //      div.balance__img
    //        img(:src="Balance" alt="balance")
    //      div.balance__info
    //        h2(v-html="$t('balance[0].head', { price: `<span class='color-accent'>200$</span>` })")
    //        p {{ $t('balance[0].text') }}
    //        div.balance__btns.d-flex.mt-5
    //          a(
    //            href="https://cabinet.easypon.in/registration"
    //            target="_blank"
    //          ).btn-fill {{ $t('btn.register') }}
    //          router-link.btn-blank(:to="{ name: 'DemoPage' }" target="_blank") {{ $t('btn.demo') }}
    section.reviews
      div.container
        div.reviews__wrapper
          h2 {{ $t('home.reviewsTitle') }}
          div.reviews__articles
            VueSlickCarousel(v-bind="settings")
              div.reviews__articles-item(
                v-for="review in reviews"
                :key="review.name"
              )
                div(:style="{ 'background-image': `url(${ review.img })` }").reviews__img
                  img(
                    :src="review.imgMain"
                    alt="review"
                  ).reviews__img-main
                p {{ review.text }}
                h4 {{ review.name }}
                span {{ review.position }}
</template>

<script>
import VueSlickCarousel from 'vue-slick-carousel'
import 'vue-slick-carousel/dist/vue-slick-carousel.css'
import 'vue-slick-carousel/dist/vue-slick-carousel-theme.css'
import Scroll from '@/assets/images/scroll.svg'
import Features from '@/assets/images/features.png'
import FeaturesMobile from '@/assets/images/features-mobile.svg'
import Balance from '@/assets/images/balance.png'
import Serv1 from '@/assets/images/services/serv1.svg'
import Serv2 from '@/assets/images/services/serv2.svg'
import Serv3 from '@/assets/images/services/serv3.svg'
import Serv4 from '@/assets/images/services/serv4.svg'
import Serv5 from '@/assets/images/services/serv5.svg'
import Serv6 from '@/assets/images/services/serv6.svg'
import ServHover1 from '@/assets/images/services/ServHover1.svg'
import ServHover2 from '@/assets/images/services/ServHover2.svg'
import ServHover3 from '@/assets/images/services/ServHover3.svg'
import ServHover4 from '@/assets/images/services/ServHover4.svg'
import ServHover5 from '@/assets/images/services/ServHover5.svg'
import ServHover6 from '@/assets/images/services/ServHover6.svg'

export default {
  name: 'HomePage',
  metaInfo () {
    return this.$metaDefault()
  },
  components: {
    VueSlickCarousel
  },
  data () {
    return {
      Scroll,
      Features,
      FeaturesMobile,
      Balance,
      items: ['Foo', 'Bar', 'Fizz', 'Buzz'],
      colors: [
        'primary',
        'secondary',
        'yellow darken-2',
        'red',
        'orange'
      ],
      servList: [
        {
          title: this.$t('home.service.0.title'),
          text: this.$t('home.service.0.text'),
          img: Serv1,
          titleHover: this.$t('home.service.0.title'),
          textHover: this.$t('servList.onuConfiguration'),
          imgHover: ServHover1
        },
        {
          title: this.$t('home.service.1.title'),
          text: this.$t('home.service.1.text'),
          img: Serv2,
          titleHover: this.$t('home.service.1.title'),
          textHover: this.$t('servList.flexiblePermissions'),
          imgHover: ServHover2
        },
        {
          title: this.$t('home.service.2.title'),
          text: this.$t('home.service.2.text'),
          img: Serv3,
          titleHover: this.$t('home.service.2.title'),
          textHover: this.$t('servList.monitoringFeatures'),
          imgHover: ServHover3
        },
        {
          title: this.$t('home.service.3.title'),
          text: this.$t('home.service.3.text'),
          img: Serv5,
          titleHover: this.$t('home.service.3.title'),
          textHover: this.$t('servList.APIIntegrations'),
          imgHover: ServHover4
        },
        {
          title: this.$t('home.service.4.title'),
          text: this.$t('home.service.4.text'),
          img: Serv4,
          titleHover: this.$t('home.service.4.title'),
          textHover: this.$t('servList.visualMapOfNetwork'),
          imgHover: ServHover5
        },
        {
          title: this.$t('home.service.5.title'),
          text: this.$t('home.service.5.text'),
          img: Serv6,
          titleHover: this.$t('home.service.5.title'),
          textHover: this.$t('servList.graphStats'),
          imgHover: ServHover6
        }
      ],
      settings: {
        dots: true,
        arrows: true,
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 850,
            settings: {
              slidesToShow: 1
            }
          }
        ]
      },
      reviews: [
        {
          img: require('@/assets/images/rev-1.jpeg'),
          imgMain: require('@/assets/images/rev-1.svg'),
          text: this.$t('home.firstReviewText'),
          name: this.$t('home.firstReviewerName'),
          position: this.$t('home.firstReviewerPosition')
        },
        {
          img: require('@/assets/images/rev-2.jpeg'),
          imgMain: require('@/assets/images/rev-2.png'),
          text: this.$t('home.secondReviewText'),
          name: this.$t('home.secondReviewerName'),
          position: this.$t('home.secondReviewerPosition')
        },
        {
          img: require('@/assets/images/rev-3.png'),
          imgMain: require('@/assets/images/FastLine.svg'),
          text: this.$t('home.thirdReviewText'),
          name: this.$t('home.thirdReviewerName'),
          position: this.$t('home.thirdReviewerPosition')
        }
      ],
      model: 0
    }
  }
}
</script>
